import '../bootstrap';

import React from 'react';
import styled, { css } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AnimatedDiv } from '../components/animated-div';
import { Box } from '../components/box';
import { Button } from '../components/button';
import { Layout } from '../components/layout';
import { MenuBar } from '../components/menu-bar/menu-bar';
import { Caption, Headline, Paragraph, SecondaryHeadline } from '../components/typography';
import { useTheme } from '../styles';

const Content = styled.div`
    background-color: ${(props) => props.theme.colors.darkred};
    padding: 20px;
    position: relative;

    button {
        margin-top: 40px;
        display: block;
    }

    .title {
        max-width: 600px;
        margin: auto;
        margin-top: 100px;
        margin-bottom: 40px;
    }

    .box {
        max-width: 600px;
        margin: 40px auto;
        padding: 40px;
    }

    @media (min-width: ${(props) => props.theme.screens.alt}px) {
        .title {
            max-width: 760px;
            font-size: ${(props) => props.theme.fontSize.subtitle}px;
        }

        .box {
            max-width: 760px;
            margin: 40px auto;
            padding: 40px;
        }

        .flex {
            display: flex;
            justify-content: space-between;
        }

        .column {
            max-width: 300px;
        }

        .alpha {
            position: absolute;
            top: -80px;
            right: -40px;
            width: 440px;
            border: 40px solid ${(props) => props.theme.colors.darkred};
            border-radius: 80px;
            border-top-left-radius: 0;
            z-index: 1000;

            & > div {
            }
        }

        .options {
            position: relative;
            max-width: 760px;
            margin: auto;
        }
    }
`;

const Label = styled.div<{ alpha?: boolean }>`
    position: absolute;
    top: 40px;
    right: 0;
    height: 30px;
    width: 151px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: ${(props) => props.color};
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: ${(props) => props.theme.screens.alt}px) {
        ${(props) =>
            props.alpha &&
            css`
                right: 400px;
            `}
    }
`;

const Border = styled(AnimatedDiv)<{ top: number; left: number }>`
    @media (min-width: ${(props) => props.theme.screens.alt}px) {
        position: absolute;
        top: ${(props) => props.top}px;
        left: ${(props) => props.left}px;
        width: 360px;
        height: 200px;
        border-top: 40px solid ${(props) => props.theme.colors.darkred};
        border-right: 40px solid ${(props) => props.theme.colors.darkred};
        border-top-right-radius: 80px;
        z-index: 2000;
    }
`;

const animationProps = {
    initialOpacity: 1,
    initialY: 100,
};

const Options = () => {
    const { darkblue, white, mostard, green } = useTheme().colors;
    const { sm, lg, xxxl } = useTheme().fontSize;
    return (
        <div className="options">
            <Border {...animationProps} top={-40} left={40} />
            <Border {...animationProps} top={408} left={440} />
            <Box {...animationProps} className="box alpha" m={0} mb={40}>
                <Label color={mostard}>
                    <Paragraph fontFamilySans size={sm} bold color={white} uppercase>
                        This month
                    </Paragraph>
                </Label>
                <div>
                    <SecondaryHeadline mt={40} mb={0} size={lg}>
                        Join the
                    </SecondaryHeadline>
                    <Headline size={xxxl}>Beta list</Headline>
                    <Caption>
                        We are just making the finishing touches and will be ready for our first
                        beta customers this month. Join our mailing list to be the first to know.
                    </Caption>
                    <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSfJbxq7dowQPSs5w-N6MJGXYdleA86dP4Ju2I0OT6XYNgPw4Q/viewform?usp=sf_link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button color={white} bg={darkblue}>
                            JOIN THE BETA LIST
                            <FontAwesomeIcon icon={'arrow-right'} />
                        </Button>
                    </a>
                </div>
            </Box>
            <Box {...animationProps} className="box" m={0} mb={80}>
                <Label alpha color={green}>
                    <Paragraph fontFamilySans size={sm} bold color={white} uppercase>
                        Now closed
                    </Paragraph>
                </Label>
                <div className="column">
                    <SecondaryHeadline mt={40} mb={0} size={lg}>
                        Become an
                    </SecondaryHeadline>
                    <Headline size={xxxl}>Alpha partner</Headline>
                    <Caption>
                        We are no longer taking alpha applications as we are now ready for our
                        public beta release.{' '}
                    </Caption>
                    <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSc-egF9PxvxftY4QdQ7rF2pC3Tu9bailG6e0jH9NeqgELYCkA/viewform?usp=sf_link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button style={{ marginTop: 70 }} color={white} bg={darkblue}>
                            Apply for pre-beta access
                            <FontAwesomeIcon icon={'arrow-right'} />
                        </Button>
                    </a>
                </div>
                <div className="flex" style={{ marginTop: 75 }}>
                    <div className="column">
                        <Caption bold>An ideal Alpha partner should:</Caption>
                        <Caption mt={10}>
                            Be large enough to have several teams doing research
                        </Caption>
                        <Caption mt={10}>
                            A service/product team at least 40 strong (the bigger the better)
                        </Caption>
                        <Caption mt={10}>
                            Be prepared to talk to us about the research you are doing under NDA
                        </Caption>
                        <Caption mt={10}>Give regular feedback on Glean.ly</Caption>
                    </div>
                    <div className="column">
                        <Caption bold mt={40}>
                            In return you will get:
                        </Caption>
                        <Caption mt={10}>Early access to the Glean.ly product</Caption>
                        <Caption mt={10}>The opportunity to guide our roadmap</Caption>
                        <Caption mt={10}>Unlimited training and support</Caption>
                        <Caption mt={10}>
                            Priority feature requests, support and reduced licence fees forever
                        </Caption>
                        <Caption mt={10}>
                            A heavily discounted licence fee for at least 12 months
                        </Caption>
                    </div>
                </div>
            </Box>
        </div>
    );
};

const App = () => {
    return (
        <Layout>
            <Content>
                <MenuBar />
                <Headline className="title" color="white" mt={100} mb={40}>
                    Get involved
                </Headline>
                <Options />
            </Content>
        </Layout>
    );
};

export default App;
